import { useContext } from "react";
import TableListData from "../../components/TableData";
import TerceiroContext from "./context";
import Content from "../../components/Layout/Content";
import DialogConfirm from "../../components/DialogConfirm";
import { toastfyError, toastfySuccess } from "../../components/Toastfy";
import { FuncTerceiro } from "./types";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { employeeOutsourced } from "../../service/requests/employeeOutsourced";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { integracao } from "../../service/api";
import { ContainerMenu2 } from "./styles";

export default function TableFunc() {
  const {
    collection,
    setIsLoading,
    showDialogConfirm,
    setShowDialogConfirm,
    setSelectedEdit,
    setShowEditModal,
    setRefresh,
    refresh,
  } = useContext(TerceiroContext);

  function handleDelete(data: FuncTerceiro | FuncTerceiro[]) {
    handleDialogConfirmClose();
    const selected = data as FuncTerceiro;
    setIsLoading(true);

    employeeOutsourced.updateStatus(selected.id).then(() => {
      toastfySuccess(
        selected.dataDesligamento
          ? "Funcionário Ativado Com Sucesso!!"
          : "Funcionário Desativado Com Sucesso!!"
      );
      setIsLoading(false);
      setRefresh(!refresh);
    });
  }

  const handleNew = () => {
    setSelectedEdit(null);
    setShowEditModal({ isVisible: true, mode: "insert" });
  };

  const handleEdit = (data: FuncTerceiro | FuncTerceiro[]) => {
    const selected = data as FuncTerceiro;
    if (selected !== undefined) {
      setSelectedEdit(selected);
      setShowEditModal({ isVisible: true, mode: "update" });
    }
  };

  const handleDialogConfirmClose = () => {
    setShowDialogConfirm({ isVisible: false, message: "", data: {} });
  };

  const handleDialogConfirmOpen = (data: FuncTerceiro | FuncTerceiro[]) => {
    const selected = data as FuncTerceiro;
    setShowDialogConfirm({
      isVisible: true,
      message: selected.dataDesligamento
        ? "Ativar Funcionário"
        : "Desativar Funcionário",
      data: data,
    });
  };

  const handleCadastroFuncionarios = () => {
    setIsLoading(true);
    integracao.cadastroFuncionarios()
      .then((success) => {
        if (success) {
          toastfySuccess("Cadastro de Funcionários realizado com sucesso!");
        } else {
          toastfyError("Erro ao cadastrar funcionários.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao cadastrar funcionários:", error);
        toastfyError("Erro ao cadastrar funcionários.");
        setIsLoading(false);
      });
  };
  
  const handleAtualizaDemitidos = () => {
    setIsLoading(true);
    integracao.atualizaDemitidos()
      .then((success) => {
        if (success) {
          toastfySuccess("Atualização de demitidos realizada com sucesso!");
        } else {
          toastfyError("Erro ao atualizar demitidos.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao atualizar demitidos:", error);
        toastfyError("Erro ao atualizar demitidos.");
        setIsLoading(false);
      });
  };
  
  

  return (
    <>
      {showDialogConfirm && (
        <DialogConfirm
          isVisible={showDialogConfirm.isVisible}
          message={showDialogConfirm.message}
          onClose={handleDialogConfirmClose}
          data={showDialogConfirm.data}
          handleAction={() => handleDelete(showDialogConfirm?.data)}
        />
      )}

      {/* Contêiner e botões com título */}
      <Container>
        <Typography variant="h5">Opções de Administrador</Typography>
        <ContainerMenu2>
          <Button color="primary" onClick={handleCadastroFuncionarios}>
            Cadastrar Funcionários
          </Button>
          {/* <Button color="secondary" onClick={handleAtualizaDemitidos}>
            Atualizar Demitidos
          </Button> */}
        </ContainerMenu2>
      </Container>

      <TableListData
        title="Funcionários Terceiros"
        data={collection}
        columns={[
          { title: "Nome", field: "nome" },
          { title: "E-mail", field: "email" },
          { title: "Cargo", field: "cargo" },
          { title: "Setor", field: "setor" },
          { title: "Função", field: "funcao" },
          { title: "Data de Admissão", field: "dataAdmissao" },
          { title: "Data de Desligamento", field: "dataDesligamento" },
        ]}
        actions={[
          {
            icon: "edit",
            tooltip: "Editar Funcionário",
            onClick: (event, data) => handleEdit(data),
          },
          (rowData) => ({
            icon: () => <PowerSettingsNewIcon />,
            tooltip: rowData.dataDesligamento
              ? "Ativar Funcionário"
              : "Desativar Funcionário",
            onClick: (event, data) => handleDialogConfirmOpen(data),
          }),
          {
            icon: "add",
            tooltip: "Cadastrar Funcionário",
            isFreeAction: true,
            onClick: () => handleNew(),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
}
