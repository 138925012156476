import axios from "axios";

export const api = axios.create({
  baseURL: "https://meritosapi.schmersalbr.com/"
  // baseURL: "http://localhost:8080/",
});


export const integracao = {
  cadastroFuncionarios: () => {
    return fetch("https://twqcomox4nnowmdqbl6jzbyybq0pckzn.lambda-url.sa-east-1.on.aws/api/cadastroFuncionarios", 
    /* return fetch("https://cadastro-meritos.sa-east-1.elasticbeanstalk.com/api/cadastroFuncionarios",*/ {
      method: "GET",
    }).then((response) => response.ok);
  },
  
  atualizaDemitidos: () => {
    return fetch("https://twqcomox4nnowmdqbl6jzbyybq0pckzn.lambda-url.sa-east-1.on.aws/api/atualizaDemitidos", {
      method: "GET",
    }).then((response) => response.ok);
  },
};



export const HTTTP_STATUS = Object.freeze({
  PENDING: "PENDING",
  FULLFILED: "FULLFILED",
  REJECTED: "REJECTED",
});
